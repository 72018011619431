import './App.css';

function App() {
  return (
    <div className="App flex items-center justify-start min-h-screen">
        <div className="container mx-auto max-w-4xl p-10 sm:p-0">
            <div className="flex flex-col items-start">
                <h1 className="text-2xl font-ABCDiatype text-left mb-8">Chromatic Engineering</h1>
                <h1 className="text-2xl font-ABCDiatypeLight text-left mb-20">A software company based started in Toronto focused on building software primarily for iOS and macOS.</h1>

                <div className="grid grid-cols-3 gap-3 w-full">
                    <div className="col-span-1">
                      
                        <div className="relative group">
                            <a href="https://usestream.app" target="_blank" rel="noopener noreferrer">
                                <h2 className="text-xl font-ABCDiatype text-left mb-2">Stream <img src='./arrow-top-right.png' alt="Stream" className="inline-block w-3 h-3 ml-1 transition-transform duration-300 group-hover:rotate-45" /></h2>
                            </a>

                            <div className="absolute bottom-[50px] left-0 w-[250px] opacity-0 translate-y-[50px] transition-all duration-300 group-hover:opacity-100 group-hover:translate-y-0 rounded-20">
                                <img src='./stream-mini.png' alt="Stream" className="rounded-3xl transform rotate-[-2deg] shadow-2xl" />
                            </div>
                        </div>

                        <p className="text-base font-ABCDiatypeLight text-left mb-2">Record your thoughts, ideas, and memories - recall them later.</p>
                        <div className="flex items-center">
                            <div className="w-4 h-4 bg-[#fcba03] rounded-full animate-pulse inline-block"></div>
                            <p className="text-sm font-ABCDiatypeLight text-left inline-block ml-2">In Progress</p>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="relative group">
                            <a href="https://rabbithole.chat" target="_blank" rel="noopener noreferrer">
                                <h2 className="text-xl font-ABCDiatype text-left mb-2">Rabbithole <img src='./arrow-top-right.png' alt="Stream" className="inline-block w-3 h-3 ml-1 transition-transform duration-300 group-hover:rotate-45" /></h2>
                            </a>

                            <div className="absolute bottom-[50px] left-0 w-[250px] opacity-0 translate-y-[50px] transition-all duration-300 group-hover:opacity-100 group-hover:translate-y-0 rounded-20">
                                <img src='./rabbithole-mini.png' alt="Rabbithole" className="rounded-3xl transform rotate-[-2deg] shadow-2xl" />
                            </div>
                        </div>

                        <p className="text-base font-ABCDiatypeLight text-left mb-2">Build a knowledge graph for your curiosities.</p>
                        <div className="flex items-center">
                            <div className="w-4 h-4 bg-green-500 rounded-full animate-pulse inline-block"></div>
                            <p className="text-sm font-ABCDiatypeLight text-left inline-block ml-2">Live</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default App;
